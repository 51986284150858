import React from 'react';
import { BrowserRouter as Router , Routes, Route} from 'react-router-dom'
import './App.css';

import landingPage from './pages/landingPage';

function App() {
  return (
    <Router>
        <Routes>
          <Route exact path="/" Component={landingPage} />
        </Routes>
    </Router>
  );
}

export default App;