import axios from 'axios';
import forge from 'node-forge';

const baseURL = 'https://mxiyazoaw.automalabs.one';

const axiosInstance = axios.create({
    baseURL,
    withCredentials: true,
    headers: {
        'Content-Type': 'multipart/form-data',
        'dmlzaW9uY29yZS5hdXRvbWEub25l': 'Yes'
    },
    timeout: 600000  // Set timeout to 10 minutes (600,000 milliseconds)
});

// Load the public key
const publicKeyPem = `
-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAupyi6erQd3JF/TciaTo3
1QiWOgfoqErVlItfKERa11HDufz4DHbaqWa7tPu8QldUChvSuqpa8XMhh7ArXKou
Z/wJ0ZnvtqftHHDmDUKDqJ7NrpK0Pr2fkJ6X5aKWOaWQ16VONpQsI7gnElIVZ5zu
O9TvtKuf32N1AqnjzlbKaow5gNzt0P2/9HlqWVVt4hCx2HmDdeWYS1PwOvmscOA5
vjMA7FuSd2DHzEeaW9xqL4heTtYe3dUWaB+1Ma0nBrXoY8B2lc7G8wUAzAOaeq+1
1CfbWQY/O7PnBpoFfy7zeVsKoqWf9ZCgaSh4NJnSGMaruXxkWLsADqFUyW3P5Gs3
FQIDAQAB
-----END PUBLIC KEY-----
`;

const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);

const encryptData = async (data) => {
    const encryptedData = {};
    const promises = Object.keys(data).map(async (key) => {
        if (Array.isArray(data[key])) {
            encryptedData[key] = await Promise.all(data[key].map(async (file) => {
                const reader = new FileReader();
                return new Promise((resolve, reject) => {
                    reader.onload = function (event) {
                        const binaryData = event.target.result;

                        // Generate a random AES key
                        const aesKey = forge.random.getBytesSync(16);
                        // Generate a random IV
                        const iv = forge.random.getBytesSync(16);
                        // Encrypt the data with the AES key
                        const cipher = forge.cipher.createCipher('AES-CBC', aesKey);
                        cipher.start({ iv });
                        cipher.update(forge.util.createBuffer(binaryData));
                        cipher.finish();
                        const encryptedBinaryData = cipher.output.getBytes();

                        // Encrypt the AES key with the RSA public key
                        const encryptedAesKey = publicKey.encrypt(aesKey, 'RSA-OAEP', {
                            md: forge.md.sha256.create(),
                            mgf: forge.mgf.mgf1.create(forge.md.sha256.create())
                        });

                        resolve({
                            encryptedBinaryData: forge.util.encode64(encryptedBinaryData),
                            encryptedAesKey: forge.util.encode64(encryptedAesKey),
                            iv: forge.util.encode64(iv), // Include the IV in the output
                            filename: file.name,
                            mimeType: file.type
                        });
                    };
                    reader.onerror = reject;
                    reader.readAsBinaryString(file);
                });
            }));
        } else if (data[key] instanceof File) {
            const reader = new FileReader();
            encryptedData[key] = await new Promise((resolve, reject) => {
                reader.onload = function (event) {
                    const binaryData = event.target.result;

                    // Generate a random AES key
                    const aesKey = forge.random.getBytesSync(16);
                    // Generate a random IV
                    const iv = forge.random.getBytesSync(16);
                    // Encrypt the data with the AES key
                    const cipher = forge.cipher.createCipher('AES-CBC', aesKey);
                    cipher.start({ iv });
                    cipher.update(forge.util.createBuffer(binaryData));
                    cipher.finish();
                    const encryptedBinaryData = cipher.output.getBytes();

                    // Encrypt the AES key with the RSA public key
                    const encryptedAesKey = publicKey.encrypt(aesKey, 'RSA-OAEP', {
                        md: forge.md.sha256.create(),
                        mgf: forge.mgf.mgf1.create(forge.md.sha256.create())
                    });

                    resolve({
                        encryptedBinaryData: forge.util.encode64(encryptedBinaryData),
                        encryptedAesKey: forge.util.encode64(encryptedAesKey),
                        iv: forge.util.encode64(iv), // Include the IV in the output
                        filename: data[key].name,
                        mimeType: data[key].type
                    });
                };
                reader.onerror = reject;
                reader.readAsBinaryString(data[key]);
            });
        } else if (typeof data[key] === 'string' || typeof data[key] === 'number') {
            const aesKey = forge.random.getBytesSync(16);
            const iv = forge.random.getBytesSync(16);
            const cipher = forge.cipher.createCipher('AES-CBC', aesKey);
            cipher.start({ iv });
            cipher.update(forge.util.createBuffer(data[key].toString()));
            cipher.finish();
            const encryptedBinaryData = cipher.output.getBytes();

            const encryptedAesKey = publicKey.encrypt(aesKey, 'RSA-OAEP', {
                md: forge.md.sha256.create(),
                mgf: forge.mgf.mgf1.create(forge.md.sha256.create())
            });

            encryptedData[key] = {
                encryptedBinaryData: forge.util.encode64(encryptedBinaryData),
                encryptedAesKey: forge.util.encode64(encryptedAesKey),
                iv: forge.util.encode64(iv) // Include the IV in the output
            };
            return Promise.resolve();
        }
    });

    await Promise.all(promises);
    return encryptedData;
};

const postData = async (path, data) => {
    try {
        const encryptedData = await encryptData(data);
        let formData = new FormData();

        for (const key in encryptedData) {
            if (Array.isArray(encryptedData[key])) {
                encryptedData[key].forEach(file => formData.append(key, JSON.stringify(file)));
            } else {
                formData.append(key, JSON.stringify(encryptedData[key]));
            }
        }

        const response = await axiosInstance.post(path, formData);
        return response.data;

    } catch (error) {
        throw error;
    }
};

const getData = async (path) => {
    try {
        const response = await axiosInstance.get(path);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export { postData, getData };